import React from 'react';

function References() {
  return (
      <section id="references" className="References">
          <h2>References</h2>
          <h3>GestaltMatcher</h3>
          <p>Hsieh, TC., Bar-Haim, A., Moosa, S. et al. <b>GestaltMatcher facilitates rare disease matching using facial
              phenotype descriptors.
              Nat Genet (2022).</b> <br/>
              <a href="https://doi.org/10.1038/s41588-021-01010-x">https://doi.org/10.1038/s41588-021-01010-x (PDF)</a>
          </p>

          <h3>GestaltMatcher Database</h3>
          <p> Lesmann, H. et al. <b>GestaltMatcher Database - a FAIR database for medical imaging data of rare disorders.
          medRxiv (2023)</b>
          <br/>
          <a href="https://https://doi.org/10.1101/2023.06.06.23290887">https://https://doi.org/10.1101/2023.06.06.23290887</a>
          </p>

      </section>
  );
}

export default References;